import React from "react";

interface FactBoxProps {
    label: string;
    value: any;
    first?: boolean;
    last?: boolean;
    labelStyle?: React.CSSProperties;
    valueStyle?: React.CSSProperties;
}

const FactBox: React.FC<FactBoxProps> = ({ label, value, first = false, last = false, labelStyle, valueStyle }) =>
    <div style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRight: !last ? '1px solid black' : undefined,
        paddingLeft: first ? 0 : '4px',
        paddingRight: last ? 0 : '4px'
    }}>
        <p style={{ textAlign: 'center', height: '50px', marginBottom: 0, ...labelStyle }}>{label}</p>
        <span style={{ fontSize: 20, marginBottom: '6px', ...valueStyle }}><b>{value}</b></span>
    </div>

export default FactBox;