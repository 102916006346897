import { Column, ColumnInstance, FilterProps, HeaderPropGetter, TableInstance, TableOptions, useExpanded, useFilters, usePagination, useSortBy, useTable } from 'react-table';
import './Table.css';
import TablePagination from './TablePagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faFilterCircleXmark, faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { Popover, PopoverHeader, PopoverBody, CloseButton, Input, Button } from 'reactstrap'
import { Fragment, MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Property } from 'csstype';
import { DebouncedFunc } from 'lodash';
import Observable from './Observable';

library.add(faFilter, faFilterCircleXmark, faSort, faSortDown, faSortUp);

const TextFilter = <T extends object>(props: FilterProps<T>) => {
  const { column: { filterValue, setFilter } } = props;
  const ref = useRef<HTMLSpanElement>(null);
  const headerName = props.column.Header as string;
  const [open, setOpen] = useState(false);
  const toggle = (e: MouseEvent<HTMLSpanElement>) => {
    setOpen(!open);
    e.stopPropagation();
  };

  return <>
    <Popover target={ref} isOpen={open} placement="bottom" >
      <PopoverHeader style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <span><CloseButton onClick={(e) => toggle(e)} /></span>
        <span style={{ paddingLeft: "4px" }}>{headerName}</span>
        {filterValue && <span style={{ paddingLeft: "4px" }}><Button onClick={() => setFilter(undefined)} size='sm'>Clear</Button></span>}
      </PopoverHeader>
      <PopoverBody>
        <Input
          value={filterValue || ''}
          onChange={e => {
            setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
          }}
          placeholder="Filter"
        />
      </PopoverBody>
    </Popover>
    <span ref={ref} onClick={toggle} style={{ textAlign: 'right', marginLeft: '4px' }}>
      {filterValue ? <FontAwesomeIcon icon={faFilterCircleXmark} /> : <FontAwesomeIcon icon={faFilter} size="sm" />}
    </span>
  </>
}

export type MyColumn<T extends Object> = (Column<T> & { textAlign?: Property.TextAlign });

export interface TableProps<T extends object> extends Omit<TableOptions<T>, 'defaultColumn'> {
  columns: MyColumn<T>[];
  fetchData?: DebouncedFunc<{ (instance: TableInstance<T>): AbortController }>;
  overrideFilters?: Partial<{ [I in keyof T]: string }>;
  pageIndexResetter?: Observable;
}
const Table = <T extends object>({ columns, overrideFilters, initialState, fetchData, pageIndexResetter, renderRowSubComponent, ...rest }: TableProps<T>) => {
  const [pageIndexNeedsReset, setPageIndexNeedsReset] = useState(false);

  const instance = useTable({
    columns,
    defaultColumn: {
      Filter: TextFilter
    },
    initialState: { pageSize: 10, ...initialState },
    ...rest
  }, useFilters, useSortBy, useExpanded, usePagination);

  useEffect(() => {
    if (!pageIndexResetter)
      return;
    const subscriptionId = pageIndexResetter.subscribe(() => setPageIndexNeedsReset(true));
    return () => pageIndexResetter.unsubscribe(subscriptionId);
  }, [pageIndexResetter, instance]);

  if (pageIndexNeedsReset) {
    instance.state.pageIndex = 0;
    setPageIndexNeedsReset(false);
  }

  useEffect(() => {
    if (overrideFilters) {
      for (const [key, value] of Object.entries(overrideFilters)) {
        instance.setFilter(key, value);
      }
    }
  }, [overrideFilters, instance]);

  useEffect(() => {
    if (!fetchData)
      return;
    const abortController = fetchData(instance);
    return () => {
      fetchData.cancel();
      abortController?.abort();
    };
  }, [instance, fetchData, instance.state.pageIndex, instance.state.filters, instance.state.sortBy, instance.state.pageSize, initialState]);

  const extraHeaderProps = useCallback((c: ColumnInstance<T>): HeaderPropGetter<T> => {
    const column = columns.find(col => c.Header === col.Header);
    const textAlign = column?.textAlign;
    const { style: sortPropsStyle, ...sortProps } = c.getSortByToggleProps();
    return { style: { ...sortPropsStyle, textAlign }, ...sortProps };
  }, [columns]);

  return <>
    <div className='my-table'>
      <div className='wrapper'>
        <table {...instance.getTableProps()}>
          <thead>
            <tr>
              {instance.headers.map(c => {
                const extra = extraHeaderProps(c);
                const props = c.getHeaderProps(extra);
                return <th {...props}>
                  {c.render('Header')}
                  <span>
                    {c.canSort && (c.isSorted
                      ? c.isSortedDesc
                        ? <FontAwesomeIcon style={{ marginLeft: '4px' }} icon={faSortDown} />
                        : <FontAwesomeIcon style={{ marginLeft: '4px' }} icon={faSortUp} />
                      : <FontAwesomeIcon style={{ marginLeft: '4px' }} icon={faSort} />)}
                  </span>
                  {c.canFilter && c.render('Filter')}
                </th>;
              })}
            </tr>
          </thead>
          <tbody {...instance.getTableBodyProps()}>
            {instance.page.map(r => {
              instance.prepareRow(r);
              const { key, ...rowProps } = r.getRowProps();
              return <Fragment key={key}>
                <tr {...rowProps}>
                  {r.cells.map(c => <td {...c.getCellProps()}>{c.render('Cell')}</td>)}
                </tr>
                {r.isExpanded ? <tr>
                  <td {...rowProps} colSpan={instance.visibleColumns.length}>
                    {renderRowSubComponent && renderRowSubComponent({ row: r })}
                  </td>
                </tr> : null
                }
              </Fragment>
            })}
          </tbody>
        </table>
      </div>
    </div>
    <TablePagination instance={instance} />
  </>;
};

export default Table;