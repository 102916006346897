import React from 'react';
import { GeostatisticsEntry } from "../datasource/types/GeostatisticsResponse";

interface GeostatisticsProps {
    geostatistics: GeostatisticsEntry;
}

interface GeostatisticsFactboxProps {
    image: string;
    label: string;
    value: React.ReactNode;
}

const GeostatisticsFactbox: React.FC<GeostatisticsFactboxProps> = ({ image, label, value }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <img style={{ marginRight: '4px' }} src={image} alt=''></img>
            <div>
                {label}<br />
                {value}
            </div>
        </div>);
}

const Geostatistics: React.FC<GeostatisticsProps> = ({ geostatistics }) => {
    return <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <GeostatisticsFactbox
                image='/images/population.svg'
                label='Total Population'
                value={geostatistics.POPULATION.toLocaleString()}
            />
            <GeostatisticsFactbox
                image='/images/employment.svg'
                label='Employment Rate'
                value={(geostatistics.EMPLOYMENT_RATE * 100).toFixed(1) + "%"}
            />
            <GeostatisticsFactbox
                image='/images/households.svg'
                label='Total Households'
                value={geostatistics.HOUSEHOLDS.toLocaleString()}
            />
        </div>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <GeostatisticsFactbox
                image='/images/median_income.svg'
                label='Median Household Income'
                value={'$' + geostatistics.MEDIAN_HOUSEHOLD_INCOME.toLocaleString()}
            />
            <GeostatisticsFactbox
                image='/images/health_coverage.svg'
                label='Healthcare Coverage Rate'
                value={(geostatistics.HEALTH_CARE_COVERAGE_RATE * 100).toFixed(1) + "%"}
            />
            <GeostatisticsFactbox
                image='/images/business.svg'
                label='Number of Doctors'
                value={geostatistics.NUMBER_NPIS_DOCTOR.toLocaleString()}
            />
        </div>
    </div>
};

export default Geostatistics;