import { TableInstance } from 'react-table';
import './Table.css';
import { useMemo, useState } from 'react';
import { debounce } from 'lodash';
import Table, { TableProps } from './Table';
import PagableRequest from '../datasource/types/PagableRequest';
import ResponseMetadata from '../datasource/types/ResponseMetadata';
import ResultWrapper from '../datasource/types/ResultWrapper';
import handleAbortError from '../datasource/handleAbortError';

interface ServerTableProps<T extends object, ResponseType extends ResultWrapper<ResponseMetadata>> extends Omit<TableProps<T>,
  'data' |
  'disableFilters' |
  'disableSortBy' |
  'fetchData' |
  'manualFilters' |
  'manualPagination' |
  'manualSortBy' |
  'pageCount'
> {
  fetchData(req: PagableRequest, signal: AbortSignal): Promise<ResponseType>;
  dataExtractor(response: ResponseType): T[];
  onNewData?(data: T[], signal: AbortSignal, setNewData: (data: T[]) => void, instance: TableInstance<T>): void;
}

/**
 * A wrapper around `Table` that fetches one page of data at a time from an API endpoint
 */
const ServerTable = <T extends object, ResponseType extends ResultWrapper<ResponseMetadata>>({ fetchData, dataExtractor, onNewData, ...rest }: ServerTableProps<T, ResponseType>) => {
  const [pageCount, setPageCount] = useState(0);
  const [data, setData] = useState<T[]>([]);

  const doFetchData = useMemo(() =>
    debounce((instance: TableInstance<T>) => {
      const abortController = new AbortController();
      fetchData({
        LIMIT: instance.state.pageSize,
        OFFSET: instance.state.pageIndex * instance.state.pageSize
      }, abortController.signal)
        .then(response => {
          setPageCount(Math.ceil(response.result.METADATA.RESULT_SET.TOTAL / instance.state.pageSize));
          const newData = dataExtractor(response);
          setData(newData);
          onNewData && onNewData(newData, abortController.signal, setData, instance);
        })
        .catch(handleAbortError);
      return abortController;
    }, 250)
    , [fetchData, dataExtractor, onNewData]);

  return <Table
    data={data}
    disableFilters={true}
    disableSortBy={true}
    manualFilters={true}
    manualPagination={true}
    manualSortBy={true}
    pageCount={pageCount}
    fetchData={doFetchData}
    {...rest}
  />;
};

export default ServerTable;