import React, { useState } from "react";
import { Accordion as AccordionR, AccordionItem, AccordionBody, AccordionHeader } from "reactstrap";

interface AccordionEntry {
    name: string;
    node: React.ReactNode;
}

interface AccordionProps {
    style?: React.CSSProperties;
    entries: AccordionEntry[];
}

const Accordion: React.FC<AccordionProps> = ({ style, entries }) => {
    const [open, setOpen] = useState<string[]>(['0']);
    const toggle = (id: string) => {
        if (open.includes(id)) {
            setOpen(open.filter(s => s !== id));
        } else {
            setOpen([...open, id]);
        }
    };

    return (
        <div style={{ overflowY: 'auto', ...style }}>
            {
                //@ts-ignore
                <AccordionR open={open} toggle={toggle} >
                    {entries.map((e, i) =>
                        <AccordionItem key={i}>
                            <AccordionHeader targetId={i.toString()}>{e.name}</AccordionHeader>
                            <AccordionBody accordionId={i.toString()}>
                                {e.node}
                            </AccordionBody>
                        </AccordionItem>
                    )}
                </AccordionR>
            }
        </div>
    );
}

export default Accordion;