import { useEffect, useState } from "react";
import { NPISummaryProps } from "./NPISummary";
import { Navigate, NavigateProps } from "react-router-dom";
import handleAbortError from "../datasource/handleAbortError";

const LandingPage: React.FC<NPISummaryProps> = (props) => {
    const [destination, setDestination] = useState<NavigateProps>();

    useEffect(() => {
        const abortController = new AbortController();
        props.dataSource.getUserDataFallible(abortController.signal)
            .then(result => {
                if (!result.AcceptedAMA_EULA) {
                    setDestination({ to: '/EULA', state: { eulaFromLandingPage: true } });
                    return;
                }
                if (!result.status) {
                    setDestination({ to: '/Onboarding' });
                    return;
                }
                setDestination({ to: "/Summary" });
            }).catch(handleAbortError);
        return () => abortController.abort();
    }, [props.dataSource])

    if (!destination)
        return <div>Loading...</div>;
    else
        return <Navigate {...destination} replace />
};

export default LandingPage;