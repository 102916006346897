import React from 'react';
import NPIData from '../view_models/NPIData';
import './NPIInfo.css'

const NPIInfo = ({ data }: { data: NPIData }) => {
    return <dl className='inline'>
        <dt>NPI:</dt>
        <dd>{data.npi}</dd>
        <dt>Practice EIN:</dt>
        <dd>{data.praciceEIN}</dd>
        <dt>NPIs in Practice:</dt>
        <dd>{data.npisInPractice}</dd>
        <dt>Primary Taxonomy:</dt>
        <dd>{data.primaryTaxonomy}</dd>
        <dt>NPI Create Date:</dt>
        <dd>{data.npiCreateDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</dd>
        <dt>NPI Update Date:</dt>
        <dd>{data.npiUpdateDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</dd>
        <dt>Gender:</dt>
        <dd>{data.gender}</dd>
        <dt>Address:</dt>
        <dd>{data.address}</dd>
        <dt>City, State:</dt>
        <dd>{data.city}, {data.state}</dd>
        <dt>Zip Code:</dt>
        <dd>{data.zipCode}</dd>
        <dt>Geo Code:</dt>
        <dd>{data.geoCode}</dd>
    </dl>
}

export default NPIInfo;