import { useRef } from "react";
import { UncontrolledTooltip } from "reactstrap";
import { ProviderEntry } from "../datasource/types/ProvidersResponse";

export interface ProviderSelectProps {
    providers: ProviderEntry[];
    providerIndex: number;
    setProviderIndex(x: number): void;
    provider: ProviderEntry | undefined;
}

const ProviderSelect: React.FC<ProviderSelectProps> = ({ providers, provider, providerIndex, setProviderIndex }) => {
    const ref = useRef<HTMLDivElement>(null);

    return <div style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <label style={{ whiteSpace: "nowrap", marginRight: '6px', marginLeft: '50px' }} htmlFor='Provider'>Provider:</label>
        <div style={{ flex: 1 }} ref={ref}>
            <select className='form-control' value={providerIndex} onChange={e => setProviderIndex(Number(e.target.value))}>
                {providers.map((provider, i) => <option key={i} value={i}>{provider.PROVIDER_NAME}</option>)}
            </select>
        </div>
        {provider && <UncontrolledTooltip placement="bottom" target={ref} innerClassName='provider-tooltip' >
            <>
                Company Data Refresh Date: {new Date(Number(provider.DATA_REFRESH_DATE.substring(0, 4)), Number(provider.DATA_REFRESH_DATE.substring(4, 2)) - 1, Number(provider.DATA_REFRESH_DATE.substring(6, 2))).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}<br />
                Company Number Of Providers: {provider.NUMBER_PROVIDERS.toLocaleString()}<br />
                Company Number Of EINs: {provider.NUMBER_EINS.toLocaleString()}<br />
                Company Number Of CPT Codes: {provider.NUMBER_CPT_CODES.toLocaleString()}<br />
                Company Number Of Billing Records: {provider.NUMBER_BILLING_RECORDS.toLocaleString()}
            </>
        </UncontrolledTooltip>}
    </div>
}

export default ProviderSelect;