import React from 'react';
import { Table } from 'reactstrap';
import RateTableEntry from '../view_models/RateTableEntry';

interface RateTableProps {
    tableEntries: RateTableEntry[];
}

const RateTable: React.FC<RateTableProps> = ({ tableEntries }) => {
    return <Table>
        <thead>
            <tr>
                <th>Peer</th>
                <th style={{ textAlign: 'right' }}>Practice Size</th>
                <th style={{ textAlign: 'right' }}>Distinct Rates</th>
                <th style={{ textAlign: 'right' }}>Min Rate</th>
                <th style={{ textAlign: 'right' }}>Max Rate</th>
                <th style={{ textAlign: 'right' }}>Average Rate</th>
                <th style={{ textAlign: 'right' }}>Median Rate</th>
                <th style={{ textAlign: 'right' }}>% of Medicare</th>
            </tr>
        </thead>
        <tbody>
            {tableEntries.map((e, i) => <tr key={i}>
                <td>{e.peer}</td>
                <td style={{ textAlign: 'right' }}>{e.practiceSize}</td>
                <td style={{ textAlign: 'right' }}>{e.distinctRates}</td>
                <td style={{ textAlign: 'right' }}>${e.minRate.toFixed(2)}</td>
                <td style={{ textAlign: 'right' }}>${e.maxRate.toFixed(2)}</td>
                <td style={{ textAlign: 'right' }}>${e.averageRate.toFixed(2)}</td>
                <td style={{ textAlign: 'right' }}>${e.medianRate.toFixed(2)}</td>
                <td style={{ textAlign: 'right' }}>{e.percentOfMedicare.toFixed(1)}%</td>
            </tr>)}
        </tbody>
    </Table>;
}

export default RateTable;