import authService from "../components/api-authorization/AuthorizeService";
import IDataSource, { UserData, UserDataResponse } from "./IDataSource";
import BillingCodesRequest from "./types/BillingCodesRequest";
import { BillingCodesResponse } from "./types/BillingCodesResponse";
import GeoRatesRequest from "./types/GeoRatesRequest";
import GeoRatesResponse from "./types/GeoRatesResponse";
import { GeostatisticsResponse } from "./types/GeostatisticsResponse";
import NPIRatesResponse from "./types/NPIRatesResponse";
import NPIRateSummaryResponse from "./types/NPIRateSummaryResponse";
import NPIRequest from "./types/NPIRequest";
import { NPIResponse } from "./types/NPIResponse";
import PeersResponse from "./types/PeersResponse";
import { ProvidersResponse } from "./types/ProvidersResponse";
import RateDetailsResponse from "./types/RateDetailsResponse";
import ResultWrapper from "./types/ResultWrapper";
import RatesRequest from "./types/RatesRequest";

class APIDataSource implements IDataSource {
    constructor(private baseUrl: string, private apiKey: string, private environmentName: string, private usePassThrough: boolean = false) { }

    public getUserData(signal: AbortSignal): Promise<UserData> {
        return this.post_impl<UserData>('users', undefined, false, signal).then((result) => result.result);
    };
    public getUserDataFallible(signal: AbortSignal): Promise<UserDataResponse> {
        return this.post_impl('users', undefined, true, signal);
    };
    public async acceptAMAEULA() {
        const endpoint = '/User/AcceptAMAEULA';
        const signal = new AbortController().signal;
        const token = await authService.getAccessToken(signal);
        const response = await fetch(endpoint, {
            method: 'POST',
            signal,
            headers: new Headers({
                'API-Key': this.apiKey,
                'Authorization': `Bearer ${token}`
            })
        });
        if (!response.ok)
            throw new Error(`API Request ${endpoint} failed with ${response.statusText ? (`'${response.statusText}'`) : ('HTTP status ' + response.status)}.`);
        const result: ResultWrapper<never> = await response.json();
        if (!result.status)
            throw new Error(`API Request ${endpoint} failed: ${result.error_message ?? 'no error_message'}.`);
    }

    private cache = new Map<string, ResultWrapper<any>>();

    private async cachePost<D, T extends ResultWrapper<D>>(resource: string, body: any, signal: AbortSignal, cacheKey: string): Promise<T> {
        if (cacheKey != null) {
            cacheKey = `${resource}//${cacheKey}`;
            const value = this.cache.get(cacheKey);
            if (value)
                return value as T;
        }

        const result = await this.post_impl<D, T>(resource, body, false, signal)

        if (cacheKey != null) {
            this.cache.set(cacheKey, result);
        }
        return result;
    }

    private async post_impl<D, T extends ResultWrapper<D> = ResultWrapper<D>>(resource: string, body: any, fallible: boolean, signal: AbortSignal): Promise<T> {
        const endpoint = this.usePassThrough ? ('/PassThrough/' + resource) : (`${this.baseUrl}${resource}/${this.environmentName}`);
        const token = await authService.getAccessToken(signal);
        const response = await fetch(endpoint, {
            method: 'POST',
            body: JSON.stringify(body),
            signal,
            headers: new Headers({
                'API-Key': this.apiKey,
                'Authorization': `Bearer ${token}`
            })
        });
        if (!response.ok)
            throw new Error(`API Request ${endpoint} failed with ${response.statusText ? (`'${response.statusText}'`) : ('HTTP status ' + response.status)}.`);
        const result: T = await response.json();
        if (!fallible && !result.status)
            throw new Error(`API Request ${endpoint} failed: ${result.error_message ?? 'no error_message'}.`);
        return result;
    }

    getBillingCodes(search: string, signal: AbortSignal): Promise<BillingCodesResponse> {
        return this.cachePost('billingcodes', {
            BILLING_CODE: search,
            LIMIT: 10,
            OFFSET: 0
        }, signal, `${search}//10//0`);
    }
    getPagedBillingCodes(request: BillingCodesRequest, signal: AbortSignal): Promise<BillingCodesResponse> {
        return this.cachePost('billingcodes', request, signal, `${request.BILLING_CODE}//${request.LIMIT}//${request.OFFSET}`);
    }
    getGeostatistics(zipCode: string, signal: AbortSignal): Promise<GeostatisticsResponse> {
        return this.cachePost('geostatistics', {
            GEOCODES: [
                { ID: zipCode, ID_TYPE: 'STATE' },
                { ID: zipCode, ID_TYPE: 'ZIP' },
                { ID: zipCode, ID_TYPE: 'CITY' }
            ]
        }, signal, zipCode);
    }
    getNPI(npi: string, signal: AbortSignal): Promise<NPIResponse> {
        return this.cachePost('npis', {
            "LIMIT": "1",
            "OFFSET": "0",
            "NPI_NUMBER": npi
        }, signal, npi);
    }
    getNPIs(request: NPIRequest, signal: AbortSignal): Promise<NPIResponse> {
        return this.cachePost('npis', request, signal, `${request.NPI_NUMBER}//${request.LIMIT}//${request.OFFSET}`);
    }
    getPeers(npi: string, signal: AbortSignal): Promise<PeersResponse> {
        return this.cachePost('peers', {
            "ID": npi,
            "ID_TYPE": "NPI",
            "PEER_INPUTS": [],
            "LIMIT": 10,
            "OFFSET": 0
        }, signal, npi);
    }
    getProviders(signal: AbortSignal): Promise<ProvidersResponse> {
        return this.cachePost('providers', {
            "PROVIDER_ID": "",
            "LIMIT": "100",
            "OFFSET": "0"
        }, signal, 'providers');
    }
    getRates(request: RatesRequest, signal: AbortSignal): Promise<NPIRatesResponse> {
        return this.cachePost('rates', request, signal, `${request.PROVIDER_ID}//${request.RATES.map(r => r.BILLING_CODE + "//" + r.ID)}`);
    }
    getNPIRateSummary(npi: string, providerId: string, signal: AbortSignal): Promise<NPIRateSummaryResponse> {
        return this.cachePost('npiratesummary', {
            "NPI_NUMBER": npi,
            "PROVIDER_ID": providerId
        }, signal, `${npi}//${providerId}`);
    }
    getGeoRates(request: GeoRatesRequest, signal: AbortSignal, NPI: string | number): Promise<GeoRatesResponse> {
        return this.cachePost('georates', {
            ...request
        }, signal, `${NPI}//${request.BILLING_CODE}//${request.PROVIDER_ID}` ?? null);
    }
    getRateDetails(request: RatesRequest, signal: AbortSignal): Promise<RateDetailsResponse> {
        return this.cachePost('ratedetails', request, signal, `${request.PROVIDER_ID}//${request.RATES.map(r => r.BILLING_CODE + "//" + r.ID)}`);
    }
}

export default APIDataSource;