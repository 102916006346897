import React from 'react';
import RateSummaryData from '../view_models/RateSummaryData';
import FactBox from './FactBox';

interface RateSummaryProps {
    style?: React.CSSProperties;
    data: RateSummaryData;
}

const RateSummary: React.FC<RateSummaryProps> = ({ data, style }) => {
    return <div style={{ display: 'flex', flexDirection: 'row', ...style }}>
        <FactBox label="NPI Distinct Rates" value={data.distinctRates} first />
        <FactBox label="NPI Min Rate" value={'$' + data.minRate.toFixed(2)} />
        <FactBox label="NPI Max Rate" value={'$' + data.maxRate.toFixed(2)} />
        <FactBox label="NPI Average Rate" value={'$' + data.averageRate.toFixed(2)} />
        <FactBox label="NPI Median Rate" value={'$' + data.medianRate.toFixed(2)} />
        <FactBox
            label="Medicare Rate"
            value={'$' + data.medicareRate.toFixed(2)}
            labelStyle={{ color: "#0094a5" }}
        />
        <FactBox
            label="Percent of Medicare"
            value={data.percentOfMedicare.toFixed(1) + '%'}
            labelStyle={{ color: "#0094a5" }}
            valueStyle={{ color: "#f9a01b" }}
        />
        <FactBox
            label="Rate Score"
            value={data.rateScore}
            last
            valueStyle={{ color: "#f9a01b" }}
        />
    </div>;
}

export default RateSummary;