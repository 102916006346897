import ProviderSelect, { ProviderSelectProps } from "./ProviderSelect";

interface ProviderSelectWrapperProps extends ProviderSelectProps, React.PropsWithChildren { }

const ProviderSelectWrapper: React.FC<ProviderSelectWrapperProps> = ({ children, ...providerSelectProps }) => {
    return <>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {children}
            </div>
            <ProviderSelect {...providerSelectProps} />
        </div>
        <hr></hr>
    </>
};

export default ProviderSelectWrapper;