import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import AsyncSelect from 'react-select/async';
import handleAbortError from '../datasource/handleAbortError';
import IDataSource from '../datasource/IDataSource';
import { NPI } from '../datasource/types/NPIResponse';
import NPIRateSummaryResponse from '../datasource/types/NPIRateSummaryResponse';
import NPIGeostatsAccordion from '../components/NPIGeostatsAccordion';
import FactBox from '../components/FactBox';
import SummaryTable from '../components/SummaryTable';
import { BillingCode } from '../datasource/types/BillingCodesResponse';
import { ProviderSelectProps } from '../components/ProviderSelect';
import maybeMask from '../datasource/maybeMask';
import ProviderSelectWrapper from '../components/ProviderSelectWrapper';
import Observable from '../components/Observable';

export interface NPISummaryProps {
    dataSource: IDataSource;
    NPI: NPI | null;
    setNPI(NPI: NPI | null): void;
    setBillingCodeInfo(code: BillingCode | null): void;
    pageIndex: number;
    setPageIndex(i: number): void;
    pageIndexResetter: Observable;
}

const npiOptionName = (o: NPI) => {
    if (o.PROVIDER_ORGANIZATION_NAME_LEGAL_BUSINESS_NAME)
        return `${maybeMask(o.NPI)} ${o.PROVIDER_ORGANIZATION_NAME_LEGAL_BUSINESS_NAME}`;
    return `${maybeMask(o.NPI)} ${o.PROVIDER_FIRST_NAME} ${o.PROVIDER_MIDDLE_NAME} ${o.PROVIDER_LAST_NAME_LEGAL_NAME} ${o.PROVIDER_CREDENTIAL_TEXT}`
}

const NPISummary: React.FC<NPISummaryProps & ProviderSelectProps> = ({ providers, providerIndex, dataSource, NPI, setNPI, setBillingCodeInfo, provider, setProviderIndex, pageIndex, setPageIndex, pageIndexResetter }) => {
    const [NPIRateSummaryResponse, setNPIRateSummaryResponse] = useState<NPIRateSummaryResponse>();

    // useMemo gives us a stable function that is not recreated every time we render.
    // This is important because we want to use the same debounced function every time, not new ones.
    const doSearch = useMemo(() =>
        debounce((searchValue: string, callback: (options: NPI[]) => void) => {
            if (!searchValue) {
                callback([]);
                return;
            }
            //TODO find a way to propagate the abort signal from debounce
            const unusedSignal = new AbortController().signal;
            dataSource.getNPIs({ NPI_NUMBER: searchValue + '%', LIMIT: '10', OFFSET: '0' }, unusedSignal)
                .then(response => {
                    callback(response.result.NPIS.map(npi => ({
                        ...npi,
                        PROVIDER_FIRST_NAME: maybeMask(npi.PROVIDER_FIRST_NAME),
                        PROVIDER_MIDDLE_NAME: maybeMask(npi.PROVIDER_MIDDLE_NAME),
                        PROVIDER_LAST_NAME_LEGAL_NAME: maybeMask(npi.PROVIDER_LAST_NAME_LEGAL_NAME),
                        PROVIDER_ORGANIZATION_NAME_LEGAL_BUSINESS_NAME: maybeMask(npi.PROVIDER_ORGANIZATION_NAME_LEGAL_BUSINESS_NAME),
                    })));
                })
                .catch(reason => {
                    handleAbortError(reason);
                    callback([]);
                });
        }, 500)
        , [dataSource]);

    // cancel search if component is unmounted
    useEffect(() => () => doSearch.cancel(), [doSearch]);

    useEffect(() => {
        if (!NPI || !provider)
            return;
        const abortController = new AbortController();
        setNPIRateSummaryResponse(undefined);
        dataSource.getNPIRateSummary(NPI.NPI.toString(), provider.PROVIDER_ID, abortController.signal)
            .then(summary => setNPIRateSummaryResponse(summary))
            .catch(handleAbortError);
        return () => abortController.abort();
    }, [dataSource, NPI, provider])

    return <>
        <ProviderSelectWrapper provider={provider} providerIndex={providerIndex} providers={providers} setProviderIndex={setProviderIndex}>
            <label style={{ whiteSpace: "nowrap", marginRight: '6px' }} htmlFor='NPI'>NPI:</label>
            <AsyncSelect<NPI>
                styles={{ container: baseStyles => ({ ...baseStyles, width: '100%' }) }}
                id='NPI'
                placeholder="Search for an NPI"
                aria-label="Search"
                loadOptions={doSearch}
                getOptionLabel={npiOptionName}
                value={NPI}
                onChange={e => setNPI(e)}
            />
        </ProviderSelectWrapper>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'row', overflow: 'hidden' }}>
            <div style={{ flex: 2, display: 'flex', flexDirection: 'column' }}>
                <div style={{ flex: 1 }}>
                    {NPIRateSummaryResponse ? <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <FactBox label="Billing Codes Monitored" value={NPIRateSummaryResponse.result.BILLING_CODES_MONITORED.toLocaleString()} first />
                        <FactBox label="Billing Rates Above Medicare" value={NPIRateSummaryResponse.result.BILLING_CODES_ABOVE_MEDICARE?.toLocaleString()} />
                        <FactBox label="Billing Rates Below Medicare" value={NPIRateSummaryResponse.result.BILLING_CODES_BELOW_MEDICARE?.toLocaleString()} />
                        <FactBox
                            label="Summary Score"
                            value={NPIRateSummaryResponse.result.RATE_SUMMARY_SCORE}
                            last
                            valueStyle={{ color: "#f9a01b" }}
                        />
                    </div> : 'Loading...'}
                </div>
                <div style={{ flex: 4, display: 'flex', flexDirection: 'column', marginTop: '10px', overflow: 'hidden' }}>
                    <SummaryTable
                        setBillingCodeInfo={setBillingCodeInfo}
                        dataSource={dataSource}
                        NPI={NPI}
                        provider={provider}
                        pageIndex={pageIndex}
                        setPageIndex={setPageIndex}
                        pageIndexResetter={pageIndexResetter}
                    />
                </div>
            </div>
            <NPIGeostatsAccordion NPI={NPI} dataSource={dataSource} />
        </div>
    </>;
}

export default NPISummary;