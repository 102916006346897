import { Table } from "reactstrap";
import GeoTableEntry from "../view_models/GeoTableEntry";

interface GeoTableProps {
    tableEntries: GeoTableEntry[];
}

const GeoTable: React.FC<GeoTableProps> = ({ tableEntries }) => {
    return <Table>
        <thead>
            <tr>
                <th>Geo Type</th>
                <th>Geo</th>
                <th style={{ textAlign: 'right' }}>Distinct Rates</th>
                <th style={{ textAlign: 'right' }}>Min Rate</th>
                <th style={{ textAlign: 'right' }}>Max Rate</th>
                <th style={{ textAlign: 'right' }}>Average Rate</th>
                <th style={{ textAlign: 'right' }}>Median Rate</th>
                <th style={{ textAlign: 'right' }}>% of Medicare</th>
            </tr>
        </thead>
        <tbody>
            {tableEntries.map((e, i) => <tr key={i}>
                <td>{e.geoType}</td>
                <td>{e.geo}</td>
                <td style={{ textAlign: 'right' }}>{e.distinctRates}</td>
                <td style={{ textAlign: 'right' }}>${e.minRate.toFixed(2)}</td>
                <td style={{ textAlign: 'right' }}>${e.maxRate.toFixed(2)}</td>
                <td style={{ textAlign: 'right' }}>${e.averageRate.toFixed(2)}</td>
                <td style={{ textAlign: 'right' }}>${e.medianRate.toFixed(2)}</td>
                <td style={{ textAlign: 'right' }}>{e.percentOfMedicare.toFixed(1)}%</td>
            </tr>)}
        </tbody>
    </Table>;
};

export default GeoTable;