import { useEffect, useState } from "react";
import handleAbortError from "../datasource/handleAbortError";
import IDataSource from "../datasource/IDataSource";
import { GeostatisticsEntry } from "../datasource/types/GeostatisticsResponse";
import { NPI } from "../datasource/types/NPIResponse";
import Accordion from "./Accordion";
import Geostatistics from "./Geostatistics";
import NPIInfo from "./NPIInfo";
import maybeMask from "../datasource/maybeMask";

interface NPIGeostatsAccordionProps {
    NPI: NPI | null;
    dataSource: IDataSource;
}

const NPIGeostatsAccordion: React.FC<NPIGeostatsAccordionProps> = ({ NPI, dataSource }) => {
    const [stateStatistics, setStateStatistics] = useState<GeostatisticsEntry>();
    const [cityStatistics, setCityStatistics] = useState<GeostatisticsEntry>();
    const [zipCodeStatistics, setZipCodeStatistics] = useState<GeostatisticsEntry>();

    const zipCode = NPI?.PROVIDER_BUSINESS_PRACTICE_LOCATION_ADDRESS_POSTAL_CODE?.substring(0, 5) || '';
    useEffect(() => {
        setCityStatistics(undefined);
        setStateStatistics(undefined);
        setZipCodeStatistics(undefined);
        if (!zipCode)
            return;
        const abortController = new AbortController();
        dataSource.getGeostatistics(zipCode, abortController.signal).then(stats => {
            for (const stat of stats.result.GEOSTATS) {
                if (stat.ID_TYPE === 'CITY')
                    setCityStatistics(stat);
                else if (stat.ID_TYPE === 'STATE')
                    setStateStatistics(stat);
                else if (stat.ID_TYPE === 'ZIP')
                    setZipCodeStatistics(stat);
            }
        })
            .catch(handleAbortError);
        return () => abortController.abort();
    }, [dataSource, zipCode]);

    return <Accordion style={{ flex: 1, marginLeft: '10px' }} entries={[
        {
            name: 'NPI',
            node: NPI && <NPIInfo data={{
                address: NPI.PROVIDER_FIRST_LINE_BUSINESS_PRACTICE_LOCATION_ADDRESS,
                city: NPI.PROVIDER_BUSINESS_PRACTICE_LOCATION_ADDRESS_CITY_NAME,
                gender: NPI.PROVIDER_GENDER_CODE,
                geoCode: NPI.PROVIDER_BUSINESS_PRACTICE_LOCATION_ADDRESS_POSTAL_CODE.substring(0, 3),
                npi: maybeMask(NPI.NPI),
                npiCreateDate: new Date(NPI.PROVIDER_ENUMERATION_DATE),
                npisInPractice: NPI.PRACTICE_NUMBER_NPIS,
                npiUpdateDate: new Date(NPI.LAST_UPDATE_DATE),
                praciceEIN: NPI.EMPLOYER_IDENTIFICATION_NUMBER_EIN,
                primaryTaxonomy: NPI.TAXONOMY_DISPLAY,
                state: NPI.PROVIDER_BUSINESS_PRACTICE_LOCATION_ADDRESS_STATE_NAME,
                zipCode: NPI.PROVIDER_BUSINESS_PRACTICE_LOCATION_ADDRESS_POSTAL_CODE
            }} />
        }, {
            name: 'State Statistics',
            node: stateStatistics && <Geostatistics geostatistics={stateStatistics} />
        }, {
            name: 'City Statistics',
            node: cityStatistics && <Geostatistics geostatistics={cityStatistics} />
        }, {
            name: 'Zip Code Statistics',
            node: zipCodeStatistics && <Geostatistics geostatistics={zipCodeStatistics} />
        }
    ]} />
}

export default NPIGeostatsAccordion;