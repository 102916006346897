import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import ApiAuthorzationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import NPISummary, { NPISummaryProps } from './pages/NPISummary';
import Rates, { RatesProps } from './pages/Rates';
import LandingPage from './pages/LandingPage';
import Onboarding from './pages/Onboarding';
import { ProviderSelectProps } from './components/ProviderSelect';
import EULA from './pages/EULA';

const AppRoutes = (props: RatesProps & NPISummaryProps & ProviderSelectProps) => [
  {
    path: '/Rates',
    requireAuth: true,
    element: <Rates {...props} />
  },
  {
    path: '/Summary',
    requireAuth: true,
    element: <NPISummary {...props} />
  },
  {
    path: '/Onboarding',
    requireAuth: true,
    element: <Onboarding />
  },
  {
    path: '/EULA',
    element: <EULA {...props} />
  },
  {
    path: '/',
    requireAuth: true,
    element: <LandingPage {...props} />
  },
  ...ApiAuthorzationRoutes,
  {
    path: '*',
    element: <Navigate to="/" />
  },
] as ({ path: string, element: ReactElement, requireAuth?: boolean })[];

export default AppRoutes;
