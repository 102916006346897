import { FormEvent, useCallback, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import IDataSource from "../datasource/IDataSource";

export interface EULAProps {
    dataSource: IDataSource;
}

const EULA: React.FC<EULAProps> = ({ dataSource }) => {
    const navigate = useNavigate();

    const onSubmit = useCallback((e: FormEvent) => {
        e.preventDefault();
        dataSource.acceptAMAEULA().then(() => navigate('/', { replace: true }));
    }, [dataSource, navigate]);

    // This is a sanity check. We should only have `eulaFromLandingPage` be truthy if we haven't accepted the EULA.
    useEffect(() => {
        if (!window.history.state.usr?.eulaFromLandingPage)
            return;
        const abortController = new AbortController();
        dataSource.getUserDataFallible(abortController.signal)
            .then(result => {
                // if we've already accepted the EULA, go to the landing page
                if (result.AcceptedAMA_EULA)
                    navigate('/', { replace: true });
            })

        return () => abortController.abort();
    }, [dataSource, navigate]);

    // Users shouldn't enter /EULA in the browser's URL field. If we got here without setting the history state, return to the landing page.
    if (!window.history.state.usr?.eulaFromLandingPage)
        return <Navigate to='/' replace />

    return <>
        <div style={{ overflowY: 'auto' }}>
            <h3><b>END USER AGREEMENT TERMS</b></h3>
            <p>(a) Licensed Content is copyrighted by the American Medical Association and CPT is a registered trademark of the AMA.</p>
            <p>(b) Licensee, as a party to a license agreement with the AMA, is authorized to grant End User a limited, non-exclusive, non-transferable, non-sublicensable license for End User to use Licensed Content in Licensee's Licensed Product(s), for the sole purpose of internal use by End User within the Territory. Upon termination or expiration of the Agreement between Licensee and AMA, Licensee shall notify End User. End User shall continue to have the right to use Licensed Content in the Licensee's Licensed Product(s) for the remainder of year of the then-current annual release (e.g., through the end of the applicable calendar year)("End User Tail Period"). End User's continued use of the Licensed Content during the End User Tail Period is subject to End User's continued compliance with all its obligations under these terms. Upon the expiration of the End User Tail Period, the sublicense granted under these terms shall automatically terminate.</p>
            <p><span style={{ fontVariantLigatures: 'none' }}>(c)</span> The provision of updated Licensed Content in the Licensed Product(s) is dependent on a continuing contractual relationship between Licensee and the AMA.</p>
            <p>(d) End User is prohibited from making Licensed Content publicly available, creating derivative works (including translating), transferring, selling, leasing, licensing, or otherwise making available to any unauthorized party the Licensed Product(s), or a copy or portion of Licensed Content to any unauthorized party, including a subsidiary, affiliate, or other legal entity, however designated, for any purpose whatsoever except as expressly permitted in this Agreement.</p>
            <p>(e) <b>End User expressly acknowledges and agrees to the extent permitted by applicable law, use of the Licensed Content is at End User's sole risk and the Licensed Content is provided "as is" without warranty of any kind. The AMA does not directly or indirectly practice medicine or dispense medical services. Fee schedules, relative value units, conversion factors and/or related components are not assigned by the AMA, are not part of CPT, and the AMA is not recommending their use. The Licensed Content does not replace the AMA's <i>Current Procedural Terminology</i> book or other appropriate coding authority. The coding information contained in the Licensed Content should be used only as a guide.</b></p>
            <p>(f) End User is required to keep records and submit reports including information necessary for the calculation of royalties payable to the AMA by the Licensee, of the same type as required of Licensee under this Agreement. End User consents to the release of such information to the AMA. End User further agrees to provide, without delay, additional information that the AMA (as a third-party beneficiary) may reasonably request, to verify the information. Nothing herein shall require End User to submit or release information that would cause End User to be in violation of applicable federal or state privacy laws.</p>
            <p>(g) The following U.S. Government End Users notice shall be included:<br /><b>U.S. Government End Users.</b> CPT is commercial technical data, which was developed exclusively at private expense by the American Medical Association (AMA), 330 North Wabash Avenue, Chicago, Illinois 60611. This agreement does not grant the Federal Government a direct license to use CPT based on FAR 52.227-14 (Data Rights - General) and DFARS 252.227-7015 (Technical Data - Commercial Items).</p>
            <p>(h) End User must ensure that anyone with authorized access to the Licensed Product(s) will comply with the provisions of the End User Agreement.</p>
            <p>(i) AMA shall be named as a third-party beneficiary of the End User Agreement.</p>
            <p>(j) End User expressly consents to the release of its name to the AMA.</p>
        </div>
        <form method="post" action="/User/AcceptAMAEULA" onSubmit={onSubmit} >
            <input className="btn btn-secondary" type="submit" value="Agree" />
        </form>
    </>;

};

export default EULA;