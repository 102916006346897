import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import { ApplicationPaths } from '../components/api-authorization/ApiAuthorizationConstants';
import './Shell.css';
import authService from '../components/api-authorization/AuthorizeService';
import handleAbortError from '../datasource/handleAbortError';

const Shell: React.FC<PropsWithChildren> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    useEffect(() => {
        const abortController = new AbortController();
        const updateIsAuthenticated = () => authService.isAuthenticated(abortController.signal)
            .then(isAuthenticated => setIsAuthenticated(isAuthenticated))
            .catch(handleAbortError);
        updateIsAuthenticated();
        const subscriptionId = authService.subscribe(() => {
            updateIsAuthenticated();
        });
        return () => {
            authService.unsubscribe(subscriptionId);
            abortController.abort();
        };
    }, []);
    return (<div className="fullscreen-height">
        <div className="navbar navbar-expand-lg navbar-dark bg-dark">
            <Container fluid>
                <Link to="/"><img className='navbar-brand' src='/images/RefMedLogoReversed.png' alt='RefMed Logo' style={{ height: '60px' }}></img></Link>
                <div style={{ flex: 1 }}></div>
                <form className='navbar-right form-inline interactive-navbar'>
                    {isAuthenticated ? <>
                        <Link className="nav-link btn-link btn text-dark bg-light" to={ApplicationPaths.Profile}>My Profile</Link>
                        <Link className="nav-link btn-link btn text-dark bg-light" to={ApplicationPaths.LogOut} state={{ local: true }}>Sign Out</Link>
                    </> : <>
                        <Link className="nav-link btn-link btn text-dark bg-light" to={ApplicationPaths.Login}>Sign In</Link>
                    </>}
                </form>
                {process.env.REACT_APP_SHOW_VERSION && <span className="navbar-text">
                    {require('../../package.json').version}
                </span>}
            </Container>
        </div>
        <Container fluid className="main-content">
            {children}
        </Container>
        <footer style={{ borderTop: 'solid black 1px' }}>
            <Container fluid>
                <center><p style={{ marginBottom: '4px' }}>CPT copyright 2021 American Medical Association. All rights reserved.</p></center>
            </Container>
        </footer>
    </div>);
};

export default Shell;