import './Rates.css';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, NavLink } from 'reactstrap';
import GeoTable from '../components/GeoTable';
import RateSummary from '../components/RateSummary';
import RateTable from '../components/RateTable';
import IDataSource from '../datasource/IDataSource';
import { BillingCode } from '../datasource/types/BillingCodesResponse';
import GeoRatesResponse from '../datasource/types/GeoRatesResponse';
import NPIRatesResponse, { NPIRate } from '../datasource/types/NPIRatesResponse';
import { NPI, NPIResponse } from '../datasource/types/NPIResponse';
import handleAbortError from '../datasource/handleAbortError';
import { Navigate } from 'react-router-dom';
import NPIGeostatsAccordion from '../components/NPIGeostatsAccordion';
import { ProviderSelectProps } from '../components/ProviderSelect';
import maybeMask from '../datasource/maybeMask';
import { useNavigate } from 'react-router-dom';
import ProviderSelectWrapper from '../components/ProviderSelectWrapper';

type Tab = 'Rates' | 'Geo'/* | 'Historical Trend'*/;

const makeTab = (name: Tab, currentTab: Tab, setCurrentTab: (x: Tab) => void) =>
    <NavLink active={currentTab === name} onClick={(e) => { e.preventDefault(); setCurrentTab(name); }} href='#' >{name}</NavLink>

export interface RatesProps {
    dataSource: IDataSource;
    NPI: NPI | null;
    billingCodeInfo: BillingCode | null;
}

const Rates: React.FC<RatesProps & ProviderSelectProps> = ({ dataSource, NPI, billingCodeInfo, provider, providers, setProviderIndex, providerIndex }) => {
    const [currentTab, setCurrentTab] = useState<Tab>('Rates');
    const [NPIRates, setNPIRates] = useState<NPIRatesResponse>();
    const [peers, setPeers] = useState<NPIResponse[]>();
    const [peerRates, setPeerRates] = useState<(NPIRate & { PRACTICE_NUMBER_NPIS: number })[]>();
    const [geoRatesResponse, setGeoRatesResponse] = useState<GeoRatesResponse>();

    const billingCode = billingCodeInfo?.BILLING_CODE || '';

    // useMemo gives us a stable function that is not recreated every time we render.
    // This is important because we want to use the same debounced function every time, not new ones.
    const doSearch = useMemo(() =>
        debounce((searchValue: string, callback: (options: BillingCode[]) => void) => {
            if (!searchValue) {
                callback([]);
                return;
            }
            //TODO find a way to propagate the abort signal from debounce
            const unusedSignal = new AbortController().signal;
            dataSource.getBillingCodes(searchValue + '%', unusedSignal)
                .then(response => {
                    callback(response.result.BILLING_CODES);
                })
                .catch(reason => {
                    handleAbortError(reason);
                    callback([]);
                });
        }, 500)
        , [dataSource]);

    // cancel search if component is unmounted
    useEffect(() => () => doSearch.cancel(), [doSearch]);

    useEffect(() => {
        if (!billingCode || !NPI || !provider)
            return;
        const abortController = new AbortController();
        setNPIRates(undefined);
        setGeoRatesResponse(undefined);
        dataSource.getRates(
            {
                RATES: [{ ID: NPI.NPI, ID_TYPE: 'NPI', BILLING_CODE: billingCode || '' }],
                PROVIDER_ID: provider.PROVIDER_ID
            }, abortController.signal)
            .then(rates => {
                if (!rates.result.NPI_RATES.length)
                    throw new Error(`No Rates returned for NPI ${NPI.NPI}, billing code ${billingCode}`);
                setNPIRates(rates);
            })
            .catch(handleAbortError);
        dataSource.getGeoRates({
            BILLING_CODE: billingCode,
            GECODES: [
                { ID_TYPE: 'STATE', ID: NPI.PROVIDER_BUSINESS_PRACTICE_LOCATION_ADDRESS_STATE_NAME },
                { ID_TYPE: 'GEO', ID: NPI.PROVIDER_BUSINESS_PRACTICE_LOCATION_ADDRESS_POSTAL_CODE.substring(0, 3) },
                { ID_TYPE: 'CITY', ID: NPI.PROVIDER_BUSINESS_PRACTICE_LOCATION_ADDRESS_CITY_NAME + ',' + NPI.PROVIDER_BUSINESS_PRACTICE_LOCATION_ADDRESS_STATE_NAME },
                { ID_TYPE: 'ZIP', ID: NPI.PROVIDER_BUSINESS_PRACTICE_LOCATION_ADDRESS_POSTAL_CODE.substring(0, 5) }
            ],
            PROVIDER_ID: provider.PROVIDER_ID
        }, abortController.signal, NPI.NPI)
            .then(geoRates => setGeoRatesResponse(geoRates))
            .catch(handleAbortError);
        return () => abortController.abort();
    }, [dataSource, billingCode, NPI, provider]);

    useEffect(() => {
        if (!peers || !billingCode || !provider)
            return;
        const abortController = new AbortController();
        setPeerRates(undefined);
        dataSource.getRates(
            {
                RATES: peers.map(peer => ({ ID: peer.result.NPIS[0].NPI, ID_TYPE: 'NPI', BILLING_CODE: billingCode })),
                PROVIDER_ID: provider.PROVIDER_ID
            }, abortController.signal)
            .then(peerRatesResult => {
                const peerRates = peerRatesResult.result.NPI_RATES
                    .sort((a, b) => a.BILLING_CODE.localeCompare(b.BILLING_CODE))
                    .map(rate => {
                        const peerNPI = peers.find(npi => npi.result.NPIS[0].NPI === rate.ID)
                        return { ...rate, PRACTICE_NUMBER_NPIS: peerNPI?.result.NPIS[0].PRACTICE_NUMBER_NPIS || 1 }
                    })
                setPeerRates(peerRates);
            })
            .catch(handleAbortError);
        return () => abortController.abort();
    }, [dataSource, billingCode, peers, provider])

    useEffect(() => {
        if (!NPI)
            return;

        const abortController = new AbortController();
        setPeers(undefined);
        dataSource.getPeers(NPI.NPI.toString(), abortController.signal)
            .then(peers => Promise.all(peers.result.PEERS.map(peer => dataSource.getNPI(peer.ID.toString(), abortController.signal))))
            .then(peers => setPeers(peers.filter(peer => peer.result.NPIS.length)))
            .catch(handleAbortError);
        return () => abortController.abort();
    }, [dataSource, NPI]);

    const navigate = useNavigate();

    if (!NPI)
        return <Navigate to="/" />;

    const loadingMessage = billingCode ? 'Loading...' : 'Select a Billing Code first.';

    const getCurrentTabContents = (): React.ReactNode => {
        switch (currentTab) {
            case "Geo":
                return (geoRatesResponse) ? <GeoTable
                    tableEntries={geoRatesResponse.result.RATES.map(rate => ({
                        geoType: rate.ID_TYPE,
                        geo: rate.ID,
                        averageRate: rate.AVG_RATE,
                        distinctRates: rate.DISTINCT_RATES,
                        maxRate: rate.MAX_RATE,
                        medianRate: rate.MEDIAN_RATE,
                        minRate: rate.MIN_RATE,
                        percentOfMedicare: rate.MEDICARE_PCT * 100
                    }))}
                /> : loadingMessage;
            // case "Historical Trend":
            //     return <div>TODO</div>;
            case "Rates":
                return (peerRates) ? <RateTable
                    tableEntries={peerRates.map(peer => ({
                        averageRate: peer.AVG_RATE,
                        distinctRates: peer.DISTINCT_RATES,
                        maxRate: peer.MAX_RATE,
                        medianRate: peer.MEDIAN_RATE,
                        minRate: peer.MIN_RATE,
                        peer: maybeMask(peer.ID),
                        practiceSize: peer.PRACTICE_NUMBER_NPIS,
                        percentOfMedicare: peer.MEDICARE_PCT * 100
                    }))}
                /> : loadingMessage;
        }
        return false;
    }

    return (<>
        <ProviderSelectWrapper provider={provider} providerIndex={providerIndex} providers={providers} setProviderIndex={setProviderIndex}>
            <Button style={{ whiteSpace: 'nowrap', marginRight: '10px' }} onClick={() => navigate(-1)}>Back to Summary</Button>
            <label>Billing Code: <b>{billingCodeInfo?.BILLING_CODE}</b> {billingCodeInfo?.MEDIUM_DESCRIPTION}</label>
        </ProviderSelectWrapper>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'row', overflow: 'hidden' }}>
            <div style={{ flex: 2, display: 'flex', flexDirection: 'column' }}>
                <div style={{ flex: 1 }}>
                    {NPIRates ? <RateSummary
                        data={{
                            distinctRates: NPIRates.result.NPI_RATES[0].DISTINCT_RATES,
                            maxRate: NPIRates.result.NPI_RATES[0].MAX_RATE,
                            minRate: NPIRates.result.NPI_RATES[0].MIN_RATE,
                            averageRate: NPIRates.result.NPI_RATES[0].AVG_RATE,
                            medianRate: NPIRates.result.NPI_RATES[0].MEDIAN_RATE,
                            medicareRate: NPIRates.result.NPI_RATES[0].MEDICARE_RATE,
                            percentOfMedicare: NPIRates.result.NPI_RATES[0].MEDICARE_PCT * 100,
                            rateScore: NPIRates.result.NPI_RATES[0].RATE_SCORE
                        }}
                    /> : loadingMessage}
                </div>
                <div style={{ flex: 2, display: 'flex', flexDirection: 'column', marginTop: '10px', overflow: 'hidden' }}>
                    <nav className="nav nav-tabs nav-fill">
                        {makeTab("Rates", currentTab, setCurrentTab)}
                        {makeTab("Geo", currentTab, setCurrentTab)}
                        {/* {makeTab("Historical Trend", currentTab, setCurrentTab)} */}
                    </nav>
                    <div style={{ flex: 1, padding: '10px', overflowY: 'auto' }}>
                        {getCurrentTabContents()}
                    </div>
                </div>
            </div>
            <NPIGeostatsAccordion NPI={NPI} dataSource={dataSource} />
        </div>
    </>
    );
};

export default Rates;