import { Link } from "react-router-dom";
import { Input } from "reactstrap"
import { BillingCode } from "../datasource/types/BillingCodesResponse";
import SummaryBillingCodeEntry from "../view_models/SummaryBillingCodeEntry";
import { useCallback, useMemo, useState } from "react";
import './SummaryTable.css'
import IDataSource from "../datasource/IDataSource";
import handleAbortError from "../datasource/handleAbortError";
import { ProviderEntry } from "../datasource/types/ProvidersResponse";
import { NPI } from "../datasource/types/NPIResponse";
import PagableRequest from "../datasource/types/PagableRequest";
import { MyColumn } from "./Table";
import ServerTable from "./ServerTable";
import { BillingCodesResponse } from "../datasource/types/BillingCodesResponse";
import { CellProps, Row, TableInstance } from "react-table";
import Observable from "./Observable";
import RatesRequest from "../datasource/types/RatesRequest";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faAngleDown, faAngleRight);

const RightAlign = ({ value }: { value: any }) => <span style={{ textAlign: 'right', display: 'block', width: '100%', paddingRight: '10px' }}>{value}</span>

const dataExtractor = (response: BillingCodesResponse) => response.result.BILLING_CODES.map<SummaryBillingCodeEntry>(b => ({ billingCode: b.BILLING_CODE, description: b.MEDIUM_DESCRIPTION, billingCodeInfo: b }));

const RateDetails: React.FC<{ row: Row<SummaryBillingCodeEntry> }> = ({ row }) => (
    <ul>
        {row.original.detailRates?.map((d, i) => <li key={i}>${d.RATE.toFixed(2)}</li>)}
    </ul>
)

interface SummaryTableProps {
    setBillingCodeInfo(code: BillingCode | null): void;
    dataSource: IDataSource;
    provider?: ProviderEntry;
    NPI: NPI | null;
    pageIndex: number;
    setPageIndex(i: number): void;
    pageIndexResetter: Observable;
}

const SummaryTable: React.FC<SummaryTableProps> = ({ provider, NPI, setBillingCodeInfo, dataSource, pageIndex, setPageIndex, pageIndexResetter }) => {
    const [billingCodeSearch, setBillingCodeSearch] = useState('');

    const onNewData = useCallback((data: SummaryBillingCodeEntry[], signal: AbortSignal, setNewData: (data: SummaryBillingCodeEntry[]) => void, instance: TableInstance<SummaryBillingCodeEntry>) => {
        setPageIndex(instance.state.pageIndex);
        if (!NPI || !provider)
            return;
        if (!data.length)
            return;
        const request: RatesRequest = {
            PROVIDER_ID: provider.PROVIDER_ID,
            RATES: data.map(b => ({ ID: NPI.NPI, ID_TYPE: 'NPI', BILLING_CODE: b.billingCode }))
        };

        const detailRates = dataSource.getRateDetails(request, signal)
        const rates = dataSource.getRates(request, signal);
        Promise.all([rates, detailRates])
            .then(([rates, detailRates]) => setNewData(rates.result.NPI_RATES
                .sort((a, b) => a.BILLING_CODE.localeCompare(b.BILLING_CODE))
                .map(r => ({ r, code: data.find(c => c.billingCode === r.BILLING_CODE) }))
                .filter(pair => pair.code)
                .map(({ r, code }) => {
                    return {
                        billingCode: r.BILLING_CODE,
                        description: code!.billingCodeInfo.MEDIUM_DESCRIPTION || '',
                        distinctRates: r.DISTINCT_RATES,
                        maxRate: r.MAX_RATE,
                        minRate: r.MIN_RATE,
                        rateScore: r.RATE_SCORE,
                        billingCodeInfo: code!.billingCodeInfo,
                        detailRates: detailRates.result.NPI_DETAIL_RATES.filter(d => d.BILLING_CODE === r.BILLING_CODE)
                    } as SummaryBillingCodeEntry;
                })))
            .catch(handleAbortError);
    }, [dataSource, NPI, provider, setPageIndex])

    const fetchData = useCallback((request: PagableRequest, signal: AbortSignal) => {
        const billingCode = billingCodeSearch ? (billingCodeSearch + '%') : '';
        return dataSource.getPagedBillingCodes({ ...request, BILLING_CODE: billingCode }, signal);
    }, [dataSource, billingCodeSearch]);

    const columns = useMemo(() => {
        return [
            {
                Header: () => null,
                id: 'expander',
                Cell: ({ row }: CellProps<SummaryBillingCodeEntry>) => (
                    <span {...row.getToggleRowExpandedProps()}>
                        {<FontAwesomeIcon icon={row.isExpanded ? faAngleDown : faAngleRight} size="sm" />}
                    </span>
                ),
            },
            { Header: 'Billing Code', accessor: 'billingCode', Cell: item => <Link onClick={() => setBillingCodeInfo(item.row.original.billingCodeInfo)} to={'/Rates'}>{item.value}</Link> },
            { Header: 'Description', accessor: 'description' },
            { Header: 'Distinct Rates', accessor: 'distinctRates', textAlign: 'right', Cell: ({ value }) => <RightAlign value={value !== undefined ? value : 'Loading...'} /> },
            { Header: 'Min Rate', accessor: 'minRate', textAlign: 'right', Cell: ({ value }) => <RightAlign value={value !== undefined ? '$' + value.toFixed(2) : 'Loading...'} /> },
            { Header: 'Max Rate', accessor: 'maxRate', textAlign: 'right', Cell: ({ value }) => <RightAlign value={value !== undefined ? '$' + value.toFixed(2) : 'Loading...'} /> },
            { Header: 'Rate Score', accessor: 'rateScore', textAlign: 'right', Cell: ({ value }) => <RightAlign value={value !== undefined ? value : 'Loading...'} /> },
        ] as MyColumn<SummaryBillingCodeEntry>[];
    }, [setBillingCodeInfo]);

    return <>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Input
                style={{ flex: 1 }}
                placeholder='Billing Code Search'
                value={billingCodeSearch}
                onChange={e => setBillingCodeSearch(e.target.value)}
            />
        </div>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            <ServerTable
                columns={columns}
                fetchData={fetchData}
                dataExtractor={dataExtractor}
                onNewData={onNewData}
                // the react-table library does its magic so `initialState` only sets the page index the "first time"
                initialState={{ pageIndex }}
                // If we want to reset the page index after the "first time", we need to engineer that ourselves.
                // We want to reset the page index to 0 if the selected practice changes.
                pageIndexResetter={pageIndexResetter}
                renderRowSubComponent={RateDetails}
            />
        </div>
    </>
}

export default SummaryTable;