import { ReactElement, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import handleAbortError from "../../datasource/handleAbortError";
import { ApplicationPaths, QueryParameterNames } from "./ApiAuthorizationConstants";
import authService from "./AuthorizeService";

interface AuthorizeRouteProps {
    path?: string;
    element?: ReactElement
}

const AuthorizeRoute: React.FC<AuthorizeRouteProps> = ({ path, element }) => {
    const [ready, setReady] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        const abortController = new AbortController()
        const populateAuthenticationState = async () => {
            let authenticated: boolean;
            try {
                authenticated = await authService.isAuthenticated(abortController.signal);
            } catch (reason) {
                handleAbortError(reason);
                return;
            }
            setReady(true);
            setAuthenticated(authenticated);
        }
        const authenticationChanged = async () => {
            setReady(false);
            setAuthenticated(false);
            await populateAuthenticationState();
        }
        let subscription: number | undefined = undefined;
        try {
            subscription = authService.subscribe(() => authenticationChanged());
            populateAuthenticationState();
        } catch (reason) { handleAbortError(reason) }
        return () => {
            abortController.abort();
            if (subscription !== undefined)
                authService.unsubscribe(subscription);
        }
    }, []);

    var link = document.createElement("a");
    link.href = path || '';
    const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
    const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(returnUrl)}`;
    if (!ready) {
        return <div></div>;
    } else {
        return authenticated ? element! : <Navigate replace to={redirectUrl} />;
    }
}

export default AuthorizeRoute;