import { useEffect, useState } from 'react';
import APIDataSource from './datasource/APIDataSource';
import IDataSource from './datasource/IDataSource';
import { NPI } from './datasource/types/NPIResponse';
import Shell from './pages/Shell';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { BillingCode } from './datasource/types/BillingCodesResponse';
import AppRoutes from './AppRoutes';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import { ProviderEntry } from './datasource/types/ProvidersResponse';
import handleAbortError from './datasource/handleAbortError';
import { RatesProps } from './pages/Rates';
import { NPISummaryProps } from './pages/NPISummary';
import { ProviderSelectProps } from './components/ProviderSelect';
import maybeMask from './datasource/maybeMask';
import Observable from './components/Observable';

const dataSource: IDataSource = new APIDataSource('', '', '', true);

const pageIndexResetter = new Observable();

function App() {
    const [NPI, setNPI] = useState<NPI | null>(null);
    const [billingCodeInfo, setBillingCodeInfo] = useState<BillingCode | null>(null);
    const [providers, setProviders] = useState<ProviderEntry[]>([]);
    const [providerIndex, setProviderIndex] = useState(0);
    const provider = providers[providerIndex] ?? undefined;

    // Stores the current NPI Summary Billing Code table page index so we stay on the same
    // page when navigating to different billing code detail pages.
    // The global `pageIndexResetter` will force the index to 0 if the selected practice changes.
    const [pageIndex, setPageIndex] = useState(0);

    useEffect(() => {
        // reset the page index if loading a new NPI
        pageIndexResetter.notifySubscribers();
        if (NPI)
            return;
        const abortController = new AbortController();
        dataSource.getUserDataFallible(abortController.signal)
            .then(result => {
                if (!result.status)
                    throw new Error("user data not ready yet");
                return result.result;
            })
            .then(userData => dataSource.getNPI(userData.DEFAULT_NPI_ID.toString(), abortController.signal))
            .then(npiResponse => setNPI(npiResponse.result.NPIS.map(npi => ({
                ...npi,
                PROVIDER_FIRST_NAME: maybeMask(npi.PROVIDER_FIRST_NAME),
                PROVIDER_MIDDLE_NAME: maybeMask(npi.PROVIDER_MIDDLE_NAME),
                PROVIDER_LAST_NAME_LEGAL_NAME: maybeMask(npi.PROVIDER_LAST_NAME_LEGAL_NAME),
                PROVIDER_ORGANIZATION_NAME_LEGAL_BUSINESS_NAME: maybeMask(npi.PROVIDER_ORGANIZATION_NAME_LEGAL_BUSINESS_NAME),
            }))[0]))
            .catch((reason: Error) => {
                if (reason.message !== 'user data not ready yet')
                    handleAbortError(reason)
            });

        return () => abortController.abort();
    }, [NPI]);

    useEffect(() => {
        if (providers.length)
            return;
        const abortController = new AbortController();
        dataSource.getUserDataFallible(abortController.signal)
            .then(result => {
                if (!result.status)
                    throw new Error("user data not ready yet");
                return result.result;
            }).then(userData => {
                const providersPromise = dataSource.getProviders(abortController.signal)
                    .then(providers => {
                        const sorted = providers.result.PROVIDERS.sort((a, b) => a.PROVIDER_NAME.localeCompare(b.PROVIDER_NAME));
                        setProviders(sorted);
                        return sorted;
                    });
                return Promise.all([userData, providersPromise]);
            }).then(([userData, providers]) => {
                const uhcIndex = providers.findIndex(p => p.PROVIDER_ID === userData.DEFAULT_PROVIDER_ID);
                setProviderIndex(uhcIndex === -1 ? 0 : uhcIndex);
            })
            .catch((reason: Error) => {
                if (reason.message !== 'user data not ready yet')
                    handleAbortError(reason)
            });
        return () => abortController.abort();
    }, [providers]);

    const props: RatesProps & NPISummaryProps & ProviderSelectProps = {
        billingCodeInfo,
        dataSource,
        NPI,
        pageIndex,
        pageIndexResetter,
        provider,
        providers,
        providerIndex,
        setBillingCodeInfo,
        setNPI,
        setPageIndex,
        setProviderIndex,
    };

    return (
        <BrowserRouter>
            <Shell>
                <Routes>
                    {AppRoutes(props).map((route, index) => {
                        const { element, requireAuth, ...rest } = route;
                        return <Route key={index} {...rest} element={requireAuth ? <AuthorizeRoute {...rest} element={element} /> : element} />;
                    })}
                </Routes>
            </Shell>
        </BrowserRouter>
    );
}

export default App;